import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
// import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function OfConsequence() {
  return (
    <Layout>
      <Helmet title="Of Consequence | Michael Weslander" />

      <h1>Of Consequence</h1>
      <h2>Michael Weslander</h2>
      <h4>Key: Em</h4>
      <h4>Tempo: 80</h4>
      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>Em</Ch>In our alloted time
          </p>
          <p>
            We've <Ch>Am</Ch>charted a course
          </p>
          <p>
            Through <Ch>Bm</Ch>decades of tragedy
          </p>
          <p>
            <Ch>Am</Ch>Fueled by divorce<Ch>Em</Ch>
          </p>
        </Verse>

        <Verse>
          <p>A promise of peace</p>
          <p>Brings us little remorse</p>
          <p>As history books still favor</p>
          <p>War for discourse</p>
        </Verse>

        <Chorus>
          <p>And you, carry on</p>
          <p>With no absence of melody</p>
          <p>You're singing our song</p>
          <p>And you, sing along</p>
          <p>But your words are confusion</p>
          <p>Your words are confused</p>
        </Chorus>

        <Verse>
          <p>The hope of extracting</p>
          <p>Meaning from lies</p>
          <p>Is met with a smile</p>
          <p>And wandering eyes</p>
        </Verse>

        <Verse>
          <p>And lining the walls</p>
          <p>Of this cumulous form</p>
          <p>Is silver now tarnished</p>
          <p>By previous storms</p>
        </Verse>

        <Chorus>
          <p>And you, carry on</p>
          <p>With no absence of melody</p>
          <p>You're singing our song</p>
          <p>And you, sing along</p>
          <p>But your words are confusion</p>
          <p>Your words are confused</p>
        </Chorus>

        <Bridge>
          <p>Em walk down</p>
        </Bridge>

        <Verse>
          <p>To want is a figment</p>
          <p>Of space and time</p>
          <p>And of consequence</p>
          <p>Are these unreadable signs</p>
        </Verse>

        <Verse>
          <p>We all need a signal</p>
          <p>A left or a right</p>
          <p>But fate would have us clash again</p>
          <p>In broad daylight</p>
        </Verse>

        <Chorus>
          <p>And you, carry on</p>
          <p>With no absence of melody</p>
          <p>You're singing our song</p>
          <p>And you, sing along</p>
          <p>But your words are confusion</p>
          <p>Your words are confused</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

